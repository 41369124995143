import React, { useState, useEffect, useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

import ChartDataLabels from "chartjs-plugin-datalabels";
import { formatNumber } from "../../utils/formatter";
import Loading from "../Loading";
import InfoModal from "../InfoModal";
import { useSelector } from "react-redux";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const EuFmTab = ({ activeTab, showBtn = true }) => {
  const { fromYear } = useSelector((state) => state.selectedYearItem);
  const euFm = useSelector((state) => state.pdfData);

  const [label, setLabel] = useState([]);
  const [dataset, setDataset] = useState([]);

  const [attainedFm, setAttainedFm] = useState([]);
  const [unaidedFm, setUnidedFm] = useState([]);
  const [fmCredit, setFmCredit] = useState([]);
  const [chartKey, setChartKey] = useState(0);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (activeTab === 3) {
      setChartKey((prevKey) => prevKey + 1);
    }
  }, [activeTab]);

  useEffect(() => {
    if (Object.keys(euFm).length > 0) {
      const externalFMcredit_G = euFm.externalFMcredit_mtCO2eq;
      const fmcreditsUnits = ` [${euFm.myFMResults?.fmcreditsUnits}]`;
      let resultsFM = euFm?.myFMResults.myResultsFM;

      const labels = Object.keys(resultsFM);

      const fmCredit = labels.map((vesselName, index) => {
        const fmData =
          resultsFM[vesselName]?.fmcredit_mtCO2eq.toFixed(2) + fmcreditsUnits ||
          0 + fmcreditsUnits;

        return fmData;
      });

      const attainedFMintensity = labels.map((vesselName, index) => {
        const attainedFMintensity =
          resultsFM[vesselName]?.attainedFMintensity || 0;
        return attainedFMintensity;
      });
      const attainedFMintensity_UnaidedByF = labels.map((vesselName, index) => {
        const attainedFMintensity_UnaidedByF =
          resultsFM[vesselName]?.attainedFMintensity_UnaidedByF || 0;
        return attainedFMintensity_UnaidedByF;
      });
      setFmCredit(fmCredit);
      setAttainedFm(attainedFMintensity);
      setUnidedFm(attainedFMintensity_UnaidedByF);

      const datasetEuFm = [
        {
          label: "Hidden",
          data: labels.map((vesselName, index) => {
            return +resultsFM[vesselName]?.hiddenColumn || 0;
          }),
          backgroundColor: "transparent",
          barThickness: 20,
        },
        {
          label: "Show",
          data: labels.map((vesselName, index) => {
            if (vesselName === "Global") {
              return externalFMcredit_G;
            } else {
              return resultsFM[vesselName]?.shownColumn || 0;
            }
          }),
          backgroundColor: labels.map(
            (vesselName, index) => resultsFM[vesselName]?.colorHex || "green"
          ),
          barThickness: 20,
        },
      ];
      setDataset(datasetEuFm);
      setLabel(labels);
    }
  }, [euFm]);

  const dataEUFM = {
    labels: label,
    datasets: dataset,
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      y: {
        stacked: true,
        grid: {
          display: true,
        },
        ticks: {
          callback: function (value, index, ticks) {
            return formatNumber(value);
          },
          font: {
            size: 15,
            family: "Cabin, sans-serif"
          },
        },
      },
      x: {
        stacked: true,
        grid: {
          display: true,
        },
        ticks: {
          display: false,
          minRotation: 90,
          color: "#000000",
          padding: 1,
          font: {
            size: 14,
            weight: "bold",
            family: "Cabin, sans-serif"
          },
        },
      },
    },
    layout: {
      padding: {
        top: 75
      }
    },
    plugins: {
      datalabels: {
        align: label.map((i) => (i === "Global" ? "start" : "end")),
        anchor: "start",
        color: "#000000",
        borderRadius: 30,
        padding: {
          left: 10,
          right: 10,
          top: 8,
          bottom: 8,
        },
        rotation: -90,
        font: function (context) {
          return {
            size: 15,
            weight: "bold",
            family: "Cabin, sans-serif"
          };
        },
        formatter: function (value, context) {
          const vesselName = label.map((i) => i);
          return vesselName[context.dataIndex];
        },
        display: function (context) {
          if (context.dataset.label === "Show") {
            return context.dataset.label;
          } else {
            return null;
          }
        },
      },
      legend: {
        display: false,
      },
      tooltip: {
        position: "myCustomPositioner",
        displayColors: false,
        callbacks: {
          label: function (context) {
            const at = attainedFm[context.dataIndex].toFixed(2);
            const fm = fmCredit[context.dataIndex];
            const un = unaidedFm[context.dataIndex].toFixed(2);
            return [
              `Attained FM Intensity: ${at} [ gCO2eq / MJ ]`,
              `FM Credit: ${fm}`,
              `Unaided Intensity: ${un} [gCO2eq/MJ]`,
            ];
          },
          title: function (context) {
            return context[0].label;
          },
        },
      },
    },
  };

  const euFmContainerRef = useRef(null);
  const euFmChartRef = useRef(null);

  useEffect(() => {
    if (show) {
      euFmContainerRef.current.scrollIntoView({ behavior: "auto" });
    }
  }, [show]);

  const handleClose = () => {
    setShow(false);
    euFmChartRef?.current?.scrollIntoView({ behavior: "auto" });
  };

  return (
    <div ref={euFmContainerRef}>
      <div className="tab-content-title">
        <h2>
          EU.FM Credits <span className="fs-4"> {fromYear.item}</span>
        </h2>
        {showBtn && (
          <button className="btn btn-primary" onClick={() => setShow(!show)}>
            Notes and Sources
          </button>
        )}
      </div>

      <div className="fleet-chart-container">
        {Object.keys(euFm).length === 0 &&
          label.length === 0 &&
          dataset.length === 0 &&
          attainedFm.length === 0 &&
          fmCredit.length === 0 &&
          unaidedFm.length === 0 && (
            <div className="d-flex align-items-center justify-content-center h-100">
              <Loading loading={Object.keys(euFm).length === 0} />
            </div>
          )}
        {Object.keys(euFm).length > 0 &&
          label.length > 0 &&
          dataset.length > 0 &&
          attainedFm.length > 0 &&
          fmCredit.length > 0 &&
          unaidedFm.length > 0 && (
            <Bar data={dataEUFM} options={options} key={chartKey} />
          )}
      </div>

      <InfoModal
        ref={euFmChartRef}
        show={show}
        handleClose={handleClose}
        data={euFm?.myFMResults?.comments}
      />
    </div>
  );
};

export default EuFmTab;
