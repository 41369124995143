import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import SelectBox from "./SelectBox";
import CloseIcon from "../assets/closeIcon.svg";
import DeleteIcon from "../assets/trash.svg";
import AddIcon from "../assets/plus.svg";
import MtIcon from "../assets/mt.svg";

const schema = yup
  .object({
    vesselName: yup.string().required("Please Enter name"),
    capacity: yup.number().required("Please Enter capacity"),
    minspeed: yup.number().required("Please Enter min speed"),
    maxspeed: yup.number().required("Please Enter max speed"),
    auxEngin: yup.array().of(
      yup.object({
        enginNumber: yup.number().required("Please Enter engin number"),
      })
    ),
    fuelType: yup.array().of(
      yup.object({
        tankSize: yup.number().required("Please Enter tank size"),
      })
    ),
  })
  .required();

const EditVesselModal = ({
  show,
  handleClose,
  listVesselType,
  listMe,
  listAux,
  listFuelType,
  auxEnginData,
  fuelData,
  data,
  isEdit,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const initialSelect = "Select";

  useEffect(() => {
    if (isEdit) {
      setValue("vesselName", data.name);
      setValue("capacity", data.capacity);
      setValue("maxspeed", data.maxSpeed);
      setValue("minspeed", data.minSpeed);

      const type = data.type;
      const foundType = listVesselType.find((item) => item.item.includes(type));

      const me = data.allEngineTypesOnboardByClass.me[0];
      const findMe = listMe.find((x) => x.item === me);

      if (foundType) {
        setSelectedItem({
          id: foundType.id,
          item: foundType.item,
        });
      } else {
        setSelectedItem({
          id: null,
          item: initialSelect,
        });
      }

      if (findMe) {
        setSelectedItemMainEngin({
          id: findMe.id,
          item: findMe.item,
        });
      } else {
      }

      const aux = data.allEngineTypesOnboardByClass.aux.map((aux) => {
        const findAux = listAux.find((x) => x.item === aux);
        return {
          id: findAux.id,
          item: findAux.item,
          enginNumber: 2,
        };
      });

      setEngineFields(aux);

      const fuels = Object.entries(data.tankCapacityM3)
        .map((f) => {
          const findFuel = listFuelType.find((x) => x.item === f[0]);
          if (findFuel) {
            return {
              id: findFuel?.id || null,
              item: findFuel?.item || initialSelect,
              tankSize: f[1] || 0,
            };
          }
        })
        .filter((fuel) => fuel !== undefined);
      setFuelFields(fuels);
      setCounter(data.allEngineTypesOnboardByClass.aux.length);
      setCounterFuelType(fuels.length);
    } else {
      reset({
        vesselName: "",
        capacity: null,
        maxspeed: null,
        minspeed: null,
      });
      setSelectedItem({ id: null, item: initialSelect });
      setSelectedItemMainEngin({ id: null, item: initialSelect });
      setSelectedItemBoilerType({ id: null, item: initialSelect });
      setEngineFields([{ id: null, item: initialSelect, enginNumber: 0 }]);
      setFuelFields([{ id: null, item: initialSelect, tankSize: 0 }]);
      setCounter(1);
      setCounterFuelType(1);
    }
  }, [isEdit]);

  // lists

  const [selectedItem, setSelectedItem] = useState({
    id: null,
    item: initialSelect,
  });
  const [selectedItemMainEngin, setSelectedItemMainEngin] = useState({
    id: null,
    item: initialSelect,
  });
  const [selectedItemBoilerType, setSelectedItemBoilerType] = useState({
    id: null,
    item: initialSelect,
  });
  const [error, setError] = useState(false);
  const [errorMainEngin, setErrorMainEngin] = useState(false);

  const [errorBoilerType, setErrorBoilerType] = useState(false);

  const [counter, setCounter] = useState(1);
  const [counterFuelType, setCounterFuelType] = useState(1);

  const [engineFields, setEngineFields] = useState([
    { id: null, item: initialSelect, enginNumber: 2 },
  ]);

  const [fuelFields, setFuelFields] = useState([
    { id: null, item: initialSelect, tankSize: 200 },
  ]);

  const initialErrors = engineFields.map(() => false);
  const [errorAuxengin, setErrorAuxEngin] = useState(initialErrors);
  const initialFuelErrors = fuelFields.map(() => false);
  const [errorFuel, setErrorFuel] = useState(initialFuelErrors);

  const handleAddField = () => {
    const list = Object.keys(auxEnginData).map((key) => ({
      id: auxEnginData[key].id,
      item: auxEnginData[key].estname,
    }));
    if (list.length > engineFields.length) {
      setEngineFields([
        ...engineFields,
        { id: null, item: initialSelect, enginNumber: 0 },
      ]);
      setCounter(counter + 1);
    } else {
      toast.error("don't allow to add more engine fields", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
        toastId: "engine-field",
      });
    }
  };

  const handleFuelType = () => {
    if (fuelData.length > fuelFields.length) {
      setFuelFields([
        ...fuelFields,
        { id: null, item: initialSelect, tankSize: 0 },
      ]);
      setCounterFuelType(counterFuelType + 1);
    } else {
      toast.error("don't allow to add more fuel fields", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
        toastId: "fuel-field",
      });
    }
  };

  const handleDeleFuelType = (index) => {
    if (counterFuelType > 1) {
      const updatedFields = [...fuelFields];
      updatedFields.splice(index, 1);
      setFuelFields(updatedFields);
      setCounterFuelType(counterFuelType - 1);

      const updatedErrors = [...errorFuel];
      updatedErrors.splice(index, 1);
      setErrorFuel(updatedErrors);
    }
  };

  const handleDeleteField = (index) => {
    if (counter > 1) {
      const updatedFields = [...engineFields];
      updatedFields.splice(index, 1);
      setEngineFields(updatedFields);
      setCounter(counter - 1);

      const updatedErrors = [...errorAuxengin];
      updatedErrors.splice(index, 1);
      setErrorAuxEngin(updatedErrors);
    }
  };

  const list = [
    {
      id: 1,
      item: "Clean Tanker 1",
    },
    {
      id: 2,
      item: "Clean Tanker 2",
    },
    {
      id: 3,
      item: "Clean Tanker 3",
    },
    {
      id: 4,
      item: "Clean Tanker 4",
    },
    {
      id: 5,
      item: "Clean Tanker 5",
    },
    {
      id: 6,
      item: "Clean Tanker 6",
    },
    {
      id: 7,
      item: "Clean Tanker 7",
    },
    {
      id: 8,
      item: "Clean Tanker 8",
    },
  ];

  const isAnyFieldNotSelected = engineFields.some(
    (field) => field.item === "Select"
  );
  const isAnyFuelFieldNotSelected = fuelFields.some(
    (field) => field.item === "Select"
  );

  const handleEditVessel = (data) => {
    if (selectedItem.id === null) setError(true);
    if (selectedItemMainEngin.id === null) setErrorMainEngin(true);
    if (selectedItemBoilerType.id === null) setErrorBoilerType(true);

    const anyFieldHasSelect = engineFields.map(
      (field) => field.item === "Select"
    );
    setErrorAuxEngin(anyFieldHasSelect);

    const anyFuelFieldHasSelect = fuelFields.map(
      (field) => field.item === "Select"
    );
    setErrorFuel(anyFuelFieldHasSelect);

    if (
      selectedItem.id !== null &&
      selectedItemMainEngin.id !== null &&
      selectedItemBoilerType.id !== null &&
      !isAnyFieldNotSelected &&
      !isAnyFuelFieldNotSelected
    ) {
      setError(false);
      setErrorMainEngin(false);
      setErrorBoilerType(false);

      data.type = selectedItem;
      data.mainEngin = selectedItemMainEngin;
      data.auxEngin = engineFields;
      data.fuelType = fuelFields;
      data.boilerType = selectedItemBoilerType;

      console.log(data);
      handleClose();
    }
  };

  const handleSelectedItem = (item, index) => {
    const updatedFields = [...engineFields];
    if (item.item === undefined) {
      updatedFields[index].item = "Select";
      updatedFields[index].id = null;
    } else {
      updatedFields[index].item = item.item;
      updatedFields[index].id = item.id;
    }

    setEngineFields(updatedFields);
  };

  const handleSelectFuelItem = (item, index) => {
    const updatedFields = [...fuelFields];

    if (item.item === undefined) {
      updatedFields[index].item = "Select";
      updatedFields[index].id = null;
    } else {
      updatedFields[index].item = item.item;
      updatedFields[index].id = item.id;
    }
    setFuelFields(updatedFields);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        centered
        size="xl"
      >
        <Modal.Header>
          <Modal.Title>
            {isEdit ? "Edit Vessel Controls" : "Add Vessel"}
          </Modal.Title>
          <button
            className="btn btn-primary close"
            onClick={() => {
              handleClose();
              if (!isEdit) {
                reset({
                  vesselName: "",
                  capacity: null,
                  enginNumber: null,
                  maxspeed: null,
                  minspeed: null,
                  tankSize: null,
                });
              }
            }}
          >
            <img src={CloseIcon} alt="close" />
          </button>
        </Modal.Header>

        <div className="row justify-content-center">
          <div className="col-xl-8">
            <Modal.Body>
              <div className="vessel-detail">
                <p className="title">Vessel</p>
                <div className="row">
                  <div className="col-md-12 col-lg-6 col-xl-6">
                    <p className="input-label">Name</p>
                    <div className="mail-input pos-relative parameter">
                      <input
                        className={`${errors.vesselName ? "error-input" : ""}`}
                        type="text"
                        name="vesselName"
                        placeholder="Enter Vessel Name"
                        {...register("vesselName")}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-6 col-xl-6">
                    <p className="input-label">Type</p>
                    <SelectBox
                      listItems={listVesselType}
                      selectedItem={selectedItem}
                      setSelectedItem={(item) => setSelectedItem(item)}
                      showError={error}
                      setShowError={setError}
                      disabledOptions={[selectedItem]}
                    />
                  </div>
                </div>
                <div className="row mt-4 border-after pb-3">
                  <div className="col-md-12 col-lg-4 col-xl-4">
                    <p className="input-label">Capacity</p>
                    <div className="mail-input pos-relative parameter">
                      <input
                        className={`${errors.capacity ? "error-input" : ""}`}
                        type="number"
                        name="capacity"
                        placeholder="Enter Capacity"
                        {...register("capacity")}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-4 col-xl-4">
                    <p className="input-label">Min Speed [ kt ]</p>
                    <div className="mail-input pos-relative parameter">
                      <input
                        className={`${errors.minspeed ? "error-input" : ""}`}
                        type="number"
                        placeholder="Enter Min Speed"
                        {...register("minspeed")}
                        name="minspeed"
                      />
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-4 col-xl-4">
                    <p className="input-label">Max Speed [ kt ] </p>
                    <div className="mail-input pos-relative parameter">
                      <input
                        className={`${errors.maxspeed ? "error-input" : ""}`}
                        type="number"
                        placeholder="Enter Max Speed"
                        {...register("maxspeed")}
                        name="maxspeed"
                      />
                    </div>
                  </div>
                </div>
                <p className="title mt-3">Engines</p>
                <div className="row">
                  <div className="col-md-12 col-xl-6 col-lg-6">
                    <p className="input-label">Main Engines Types</p>
                    <SelectBox
                      listItems={listMe}
                      selectedItem={selectedItemMainEngin}
                      setSelectedItem={(item) => setSelectedItemMainEngin(item)}
                      showError={errorMainEngin}
                      setShowError={setErrorMainEngin}
                      disabledOptions={[selectedItemMainEngin]}
                    />
                  </div>
                </div>

                <div>
                  {engineFields.map((field, index) => {
                    return (
                      <div className="row mt-4 align-items-end" key={index}>
                        <div className="col-md-12 col-xl-6 col-lg-6">
                          {index === 0 && (
                            <p className="input-label">
                              Auxiliary Engines Types
                            </p>
                          )}
                          <SelectBox
                            listItems={listAux}
                            selectedItem={field}
                            setShowError={(value) => {
                              const updatedErrors = [...errorAuxengin];
                              updatedErrors[index] = value === "Select";
                              setErrorAuxEngin(updatedErrors);
                            }}
                            showError={errorAuxengin[index]}
                            setSelectedItem={(item) =>
                              handleSelectedItem(item, index)
                            }
                            disabledOptions={engineFields
                              .filter(
                                (f, i) => i !== index && f.item !== "Select"
                              )
                              .map((f) => f.id)}
                          />
                        </div>
                        <div className="col-md-3">
                          {index === 0 && (
                            <p className="input-label">
                              Auxiliary Engines Number
                            </p>
                          )}
                          <div className="mail-input pos-relative parameter mt-2 mt-lg-0">
                            <input
                              className={`${
                                errors.enginNumber ? "error-input" : ""
                              }`}
                              type="number"
                              name={`enginNumber[${index}]`}
                              placeholder="Enter Engine Number"
                              {...register(`enginNumber[${index}]`)}
                              value={field.enginNumber}
                              onChange={(e) => {
                                const updatedFields = [...engineFields];
                                const number = +e.target.value;
                                updatedFields[index].enginNumber = number;
                                setEngineFields(updatedFields);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-1">
                          <div className="d-flex justify-content-lg-center">
                            <button
                              className="btn btn-secondary delete"
                              onClick={() => handleDeleteField(index, field)}
                            >
                              <img src={DeleteIcon} alt="delete" />
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  <div className="row mt-2">
                    <div className="col-md-2">
                      <div>
                        <button
                          className="btn btn-primary add"
                          onClick={handleAddField}
                        >
                          <img src={AddIcon} alt="" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-4 border-after pb-4">
                  <div className="col-md-12 col-xl-6 col-lg-6">
                    <p className="input-label">Boiler Type</p>
                    <SelectBox
                      listItems={list}
                      selectedItem={selectedItemBoilerType}
                      setSelectedItem={(item) =>
                        setSelectedItemBoilerType(item)
                      }
                      setShowError={setErrorBoilerType}
                      showError={errorBoilerType}
                      disabledOptions={[selectedItemBoilerType]}
                    />
                  </div>
                </div>

                <p className="title mt-3">Fuel Type</p>
                {fuelFields.map((field, index) => {
                  return (
                    <div className="row align-items-end mt-2" key={index}>
                      <div className="col-md-5">
                        <SelectBox
                          listItems={listFuelType}
                          selectedItem={field}
                          setShowError={(value) => {
                            const updatedErrors = [...errorFuel];
                            updatedErrors[index] = value === "Select";
                            setErrorFuel(updatedErrors);
                          }}
                          showError={errorFuel[index]}
                          setSelectedItem={(item) =>
                            handleSelectFuelItem(item, index)
                          }
                          disabledOptions={fuelFields
                            .filter(
                              (f, i) => i !== index && f.item !== "Select"
                            )
                            .map((f) => f.id)}
                        />
                      </div>
                      <div className="col-md-6 col-xl-4 col-lg-4">
                        {index === 0 && (
                          <p className="input-label">tank Size</p>
                        )}
                        <div className="mail-input pos-relative parameter">
                          <input
                            className={`${
                              errors.tankSize ? "error-input" : ""
                            }`}
                            type="number"
                            name={`tankSize[${index}]`}
                            placeholder="Enter Tank Size"
                            {...register(`tankSize[${index}]`)}
                            value={field.tankSize}
                            onChange={(e) => {
                              const updatedFields = [...fuelFields];
                              const number = +e.target.value;
                              updatedFields[index].tankSize = number;
                              setFuelFields(updatedFields);
                            }}
                          />
                          <img src={MtIcon} alt="mt" />
                        </div>
                      </div>
                      <div className="col-md-1">
                        <div className="d-flex  justify-lg-content-center">
                          <button
                            className="btn btn-secondary delete"
                            onClick={() => handleDeleFuelType(index, field)}
                          >
                            <img src={DeleteIcon} alt="" />
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}

                <div className="row mt-3">
                  <div className="col-md-2">
                    <div>
                      <button
                        className="btn btn-primary add"
                        onClick={handleFuelType}
                      >
                        <img src={AddIcon} alt="" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
              <Button
                className="btn btn-secondary btn-lg"
                type="button"
                onClick={() => {
                  handleClose();
                  if (!isEdit) {
                    reset({
                      vesselName: "",
                      capacity: null,
                      enginNumber: null,
                      maxspeed: null,
                      minspeed: null,
                      tankSize: null,
                    });
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                className="btn btn-primary btn-lg"
                type="submit"
                onClick={handleSubmit(handleEditVessel)}
              >
                Save
              </Button>
            </Modal.Footer>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditVesselModal;
