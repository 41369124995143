  import React, { useEffect, useRef, useState } from "react";
  import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from "chart.js";
  import { Bar } from "react-chartjs-2";
  import ChartDataLabels from "chartjs-plugin-datalabels";
  import { formatNumber, roundUpToNearest } from "../../utils/formatter";
  import Loading from "../Loading";
  import InfoModal from "../InfoModal";
import { useSelector } from "react-redux";
import { barPatterns } from "../../utils/barPatterns";

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
  );

  const EtsTab = ({ activeTab, showBtn = true }) => {
    const { fromYear } = useSelector((state) => state.selectedYearItem);
    const ets = useSelector((state) => state.pdfData);
    const [label, setLabel] = useState([]);
    const [dataset, setDataset] = useState([]);
    const [costUsd, setCostUsd] = useState([]);
    const [chartKey, setChartKey] = useState(0);
    const [show, setShow] = useState(false);

    const etsContainerRef = useRef(null);
    const etsChartRef = useRef(null);

    useEffect(() => {
      if (Object.keys(ets).length > 0) {
        const resultsETS = ets?.myETSResults?.myResultsETS;
        const labels = Object.keys(resultsETS);
        const pollutantNames = Array.from(
          new Set(
            Object.values(resultsETS).flatMap((location) =>
              Object.values(location).flatMap(
                (pollutant) => pollutant.pollutantName
              )
            )
          )
        );

        const datasets = pollutantNames.map((pollutant) => {
          const data = labels.map((label) => {
            const etsData = resultsETS[label];
            return etsData[pollutant]
              ? +etsData[pollutant].etscostUSD.toFixed(2)
              : 0;
          });

          const dataetscostUSD = labels.map((label) => {
            const etsData = resultsETS[label];
            return etsData[pollutant] ? etsData[pollutant].co2eq_EmittedMt : 0;
          });

          const vesselTotalCostArray = [];

          Object.values(resultsETS).forEach((vessel) => {
            let totalCost = 0;

            Object.values(vessel).forEach((fuel) => {
              totalCost += fuel.etscostUSD;
            });
            vesselTotalCostArray.push(totalCost);
          });

          setCostUsd(vesselTotalCostArray);

          const quantityEmittedMt = labels.map((label) => {
            const etsData = resultsETS[label];
            return etsData[pollutant]
              ? etsData[pollutant].quantityEmittedMt.toFixed(2)
              : 0;
          });

          const backgroundColors = labels.map((label) => {
            const etsData = resultsETS[label];
            return etsData[pollutant]
              ? etsData[pollutant].renderStyle.myColor
              : "";
          });
          const filteredColors = backgroundColors.filter((color) => color !== "");
          const backgroundColor = Array.from(new Set(filteredColors));

          return {
            label: pollutant,
            data,
            quantityEmittedMt: quantityEmittedMt,
            dataetscostUSD: dataetscostUSD,
            backgroundColor,
            barThickness: 10,
            border: 0,
            barPercentage: 0.8,
            categoryPercentage: 0.5,
          };
        });
        setDataset(datasets);
        setLabel(labels);
      }
    }, [ets]);

    useEffect(() => {
      if (activeTab === 2) {
        setChartKey((prevKey) => prevKey + 1);
      }
    }, [activeTab]);
    
    const dataEts = {
      labels: label,
      datasets: dataset,
    };

    const maxDataValue = Math.max(...dataset.flatMap((item) => item.data));

    Tooltip.positioners.myCustomPositionerLeft = function(elements, eventPosition) {
      const tooltip = this;
      const chart = tooltip.chart;
      const x = eventPosition.x;
      const y = eventPosition.y;

      const chartArea = chart.chartArea;
      const leftEdge = chartArea.left;
      const rightEdge = chartArea.right;
      const topEdge = chartArea.top;
      const bottomEdge = chartArea.bottom;

      const margin = 20; 

      if (x < leftEdge - margin || x > rightEdge + margin || y < topEdge - margin || y > bottomEdge + margin) {
        tooltip.active = []; 
        tooltip.update(true); 
        return false; 
      }

      let xAlign = 'right'; 

      if (x <= leftEdge + margin) {
        xAlign = 'left';
      } else if (x >= rightEdge - margin) {
        xAlign = 'right';
      }

      return {
        x: x,
        y: y,
        xAlign: xAlign,
        yAlign: 'center' 
      };
    };
    
    

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      indexAxis: "y",
      scales: {
        x: {
          stacked: true,
          grid: {
            display: true,
          },
          display: true,
          title: {
            display: true,
            text: `ETS Cost [USD]`,
            font: {
              size: 15,
              weight: "bold",
              family: "Cabin, sans-serif"
            },
            padding: {
              top: 30,
            },
          },
          ticks: {
            callback: function(value) {
              return "$" + formatNumber(value);
            },
            color: "#212529",
            font: {
              size: 15,
              family: "Cabin, sans-serif"
            },
          },
        },
        y: {
          stacked: true,
          grid: {
            display: true,
          },
          ticks: {
            crossAlign: "far",
            color: "#000000",
            padding: 20,
            font: {
              size: 15,
              weight: "bold",
              family: "Cabin, sans-serif"
            },
            autoSkip:false,
          },
          border: {
            display: false,
          },
        },
      },
      layout: {
        padding: {
          top: 75,
        }
      },
      plugins: {
        datalabels: {
          display: false,
        },
        tooltip: {
          position: "myCustomPositionerLeft",
          displayColors: false,
          callbacks: {
            label: function (context) {
              if (context !== undefined) {
                const dataIndex = context.dataIndex;
                const qt = context.dataset.quantityEmittedMt[dataIndex];
                const usd = context.dataset.dataetscostUSD[dataIndex].toFixed(2);
                let textArr = costUsd.map((i) => i.toFixed(0));
                const text = "$" + formatNumber(textArr[dataIndex]);
                const maxLineLength = Math.max(
                  `Quantity Emitted: ${formatNumber(qt)} mt `.length,
                  `CO2 eq: ${formatNumber(usd)} mt`.length,
                  `ETS cost: ${context?.formattedValue} USD`.length,
                  `Total ETS: ${text}`.length
                );
                const divider = '-'.repeat(maxLineLength+10);
                return [
                  `Quantity Emitted: ${formatNumber(qt)} mt `,
                  `CO2 eq: ${formatNumber(usd)} mt`,
                  `ETS cost: ${context?.formattedValue} USD`,
                  divider,
                  `Total ETS: ${text}`
                ];
              }
              return context.label;
            },
            title: function (context) {
              if (context[0] !== undefined) {
                return context[0].label + "-" + context[0].dataset.label;
              }
            },
          },
        },
        legend: {
          position: "bottom",
          labels: {
            padding: 40,
            color: "#212529",
            font: {
              size: 15,
              family: "Cabin, sans-serif"
            },
          },
        },
      },
    };

    useEffect(() => {
      if (show) {
        etsContainerRef.current.scrollIntoView({ behavior: "auto" });
      }
    }, [show]);

    const handleClose = () => {
      setShow(false);
      etsChartRef?.current?.scrollIntoView({ behavior: "auto" });
    };

    return (
      <div ref={etsContainerRef}>
        <div className="tab-content-title">
          <h2>
            ETS {showBtn ? "Tab" : ""}{" "}
            <span className="fs-4"> {fromYear.item}</span>
          </h2>
          {showBtn && (
            <button className="btn btn-primary" onClick={() => setShow(!show)}>
              Notes and Sources
            </button>
          )}
        </div>
        <div className="fleet-chart-container">
          {Object.keys(ets).length === 0 && (
            <div className="d-flex align-items-center justify-content-center h-100">
              <Loading loading={Object.keys(ets).length === 0} />
            </div>
          )}
          {Object.keys(ets).length > 0 && (
            <>
              <Bar
                data={dataEts}
                options={options}
                plugins={[barPatterns]}
                key={chartKey}
              />
            </>
          )}
        </div>
        <InfoModal
          ref={etsChartRef}
          show={show}
          handleClose={handleClose}
          data={ets?.myETSResults?.comments}
        />
      </div>
    );
  };

  export default EtsTab;